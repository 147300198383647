import { gql } from "@apollo/client";

export const GET_BACK_SCREENS = gql`
    query($userId: String! ) {
        getBackScreens(userId: $userId) {
            id
            name
            about
            isOwnedByUser
            isOwnedByPlatform
            filters {
                title
                calc
                scope
                about
                type
                descriptiveValue
            }
            rows {
                title
                calc
                scope
                about
                collectionId
                collectionName
            }
            selectedRowTitle
        }
    }
`;

export const GET_BACK_SCREEN = gql`
    query($userId: String!, $id: String!) {
        getBackScreen(userId: $userId, id: $id) {
            id
            name
            about
            isOwnedByUser
            isOwnedByPlatform
            filters {
                title
                calc
                scope
                about
                type
                descriptiveValue
            }
            rows {
                title
                calc
                scope
                about
                collectionId
                collectionName
            }
            selectedRowTitle
        }
    }
`;

export const SAVE_BACK_SCREEN = gql`
    mutation saveBackScreen($backScreen: JSON!, $userId: String ) {
        saveBackScreen(
            backScreen: $backScreen,
            userId: $userId
        ) {
            id
            name
            about
            isOwnedByUser
            isOwnedByPlatform
            filters {
                title
                calc
                scope
                about
                type
                descriptiveValue
            }
            rows {
                title
                calc
                scope
                about
                collectionId
                collectionName
            }
            selectedRowTitle
        }
    }

`;