import React, { FunctionComponent } from 'react';
import { Box, Paper, Typography,Link  } from '@mui/material';
import { Link as Link_ } from "react-router-dom";

import { useTooltipDataContextProvider } from "@state/byModel/Tooltip/tooltip.context";

import Histogram from "@components/shared/Histogram";

import { useStyles } from './styles';

export const Tooltip: FunctionComponent<{}> = ({ }) => {

    const { showtooltip, tooltipType, tooltipData, tooltipPosition, closeTooltip, setMouseOverTooltip } = useTooltipDataContextProvider();
    const classes = useStyles().classes;

    return tooltipType ? (
        <Box
            position="fixed"
            display={showtooltip ? 'block' : 'none'}
            sx={{ top: tooltipPosition.y, left: tooltipPosition.x, zIndex: '10000' }}
        >
            <Box
                className={classes.tooltip} 
                onMouseEnter={setMouseOverTooltip as React.MouseEventHandler<HTMLDivElement>}
                onMouseLeave={closeTooltip as React.MouseEventHandler<HTMLDivElement>}
            >
                {/* <div className={classes.elementHover}></div> */}
                <Paper sx={{ opacity: 0.95 }}>
                    <Box p={2} px={4}>
                        {tooltipType === 'histogram' ? (
                            <>
                                <Typography fontWeight="bold" variant="body2" pb={1}>{tooltipData.title}</Typography>
                                <Histogram
                                    width={450}
                                    height={150}
                                    // numberOfBuckets={8}
                                    data={tooltipData.data}
                                    valueOfSelectedCompany={tooltipData.valueOfSelectedCompany}
                                    showTicks={true}
                                    bigTicks={true}
                                    stringToCauseRerenderWhenChanged={tooltipData.title}
                                    whatsBetter={tooltipData.whatsBetter}
                                />

                                {/* <br />

                                <Link
                                    component={Link_}
                                    // sx={{ textDecoration: 'none' }}
                                    color="inherit"
                                    to={
                                        {
                                            pathname: `/stock`,
                                            // search: (getNewSearchParamsString({ keysToRemove: ['ticker'] }) as string)
                                        }
                                    }
                                    target="_blank"
                                >See screener for Industry</Link> */}

                            </>
                        ) : null}
                    </Box>
                </Paper>
            </Box>

            {/* <pre>{JSON.stringify(tooltipData.data, null, 4)}</pre> */}
        </Box>
    ) : null;
}

