import React, { createContext, useContext, useState, useEffect } from "react";
import { useQuery, useMutation, useLazyQuery } from "@apollo/client";
import { GET_LOCAL_SCREENS, UPDATE_LOCAL_SCREENS } from "@state/byModel/SelectedBackScreens/SelectedBackScreens.localQueries";

interface ContextType {
  backScreeners: Array<any>
  updateBackScreeners: Function
  filterBeingEdited: Boolean | Object
  updateFilterBeingEdited: Function,
  rowBeingEdited: Boolean | Object,
  updateRowBeingEdited: Function,
  screenBeingEditedName: Boolean | String,
  updateScreenBeingEditedName: Function,
  updateScreens?: Function,
  updateScreens_?: Function
}


export const BackScreenContext = createContext<ContextType>({
  backScreeners: [],
  filterBeingEdited: false,
  rowBeingEdited: false,
  screenBeingEditedName: false,

  updateScreens_: () => { },
  updateScreens: () => { },
  updateBackScreeners: () => { },
  updateFilterBeingEdited: () => { },
  updateRowBeingEdited: () => { },
  updateScreenBeingEditedName: () => { },
});

export const BackScreenProvider = ({ children }) => {

  const { loading: localStorageScreensLoading, error: localStorageScreensError, data: localStorageScreensData, refetch: updateScreens_ } = useQuery(GET_LOCAL_SCREENS);
  const [updateScreens] = useMutation(UPDATE_LOCAL_SCREENS);

  const [backScreeners, updateBackScreeners] = useState(localStorageScreensData?.screens?.length ? localStorageScreensData?.screens : []);
  const [filterBeingEdited, updateFilterBeingEdited] = useState(false);
  const [rowBeingEdited, updateRowBeingEdited] = useState(false);
  const [screenBeingEditedName, updateScreenBeingEditedName] = useState(false);


  useEffect(() => {
    // console.log({ backScreeners, localStorageScreensData });
    if (localStorageScreensData?.screens[0]?.id !== backScreeners[0]?.id) {
      updateScreens({
        variables: {
          screens: backScreeners
        }
      });
    }
  }, [backScreeners]);

  useEffect(() => {
    // console.log({ localStorageScreensData });
    if (localStorageScreensData?.screens[0]?.id !== backScreeners[0]?.id) {
      updateBackScreeners(localStorageScreensData?.screens);
    }

  }, [localStorageScreensData]);


  return (
    <BackScreenContext.Provider
      value={{
        backScreeners,
        updateScreens,
        updateScreens_,
        updateBackScreeners,
        filterBeingEdited,
        updateFilterBeingEdited,
        rowBeingEdited,
        updateRowBeingEdited,
        screenBeingEditedName,
        updateScreenBeingEditedName
      }}
    >
      {children}
    </BackScreenContext.Provider>
  );
};

export const useBackScreenProvider = () => {
  return useContext(BackScreenContext);
};
