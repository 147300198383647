import { gql } from '@apollo/client';
// import RandomID from "random-id";
// import { GET_LOCAL_SCREENS } from "./FilterOptions.localQueries";

export const selectedRatioCollectionsResolvers = {
  Mutation: {
    updateSelectedRatioCollections: (_: any, variables: any, { cache }: any) => {
      // const { screens } = cache.readQuery({ query: GET_LOCAL_SCREENS });

      cache.writeQuery({
        query: gql`
          query {
            selectedRatioCollections {
              value
              title
              type
            }
          }
        `,
        data: {
          selectedRatioCollections: [...variables.selectedRatioCollections]
        }
      });

      return variables.selectedRatioCollections;
    }
  }
};