import { gql } from '@apollo/client';
// import RandomID from "random-id";
// import { GET_LOCAL_SCREENS } from "./FilterOptions.localQueries";

export const screensResolvers = {
  Mutation: {
    updateScreens: (_: any, variables: any, { cache }: any) => {

      // const { screens } = cache.readQuery({ query: GET_LOCAL_SCREENS });

      cache.writeQuery({
        query: gql`
          query {
            screens {
              id
              name
              about
              isOwnedByUser
              isOwnedByPlatform
              filters {
                  title
                  calc
                  scope
                  about
                  type
                  descriptiveValue
              }
              rows {
                  title
                  calc
                  scope
                  about
                  collectionId
                  collectionName
              }
              selectedRowTitle
            }
          }
        `,
        data: {
          screens: [...variables.screens?.map(screen => ({
            id: screen.id,
            name: screen.name,
            about: screen.about || null,
            isOwnedByUser: screen.isOwnedByUser,
            isOwnedByPlatform: screen.isOwnedByPlatform,
            filters: screen.filters?.map(f => ({ ...f, about: f.about || null })) || [],
            rows: screen.rows || [],
            selectedRowTitle: screen.selectedRowTitle
          }))]
        }
      });

      return variables.screens;
    }
  }
};