import React, { FunctionComponent, useState, useEffect } from "react";
import { useParams, useRouteMatch, useLocation } from "react-router-dom";
import { useQuery, useMutation, useLazyQuery } from "@apollo/client";
import { LinkedIn } from '@mui/icons-material';
import {
    Paper,
    Box,
    Typography,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Button,
    TextField,
    Fab,
    Chip,
    Link,
    Autocomplete
} from '@mui/material';

import { GET_USER_BY_ID } from '@state/byModel/User/User.queries';
import { GET_USER_KEYS, ADD_USER_KEY } from "@state/byModel/User/UserKey.localQueries";
import useSearchParams from '@state/byModel/Global/useSearchParams.effect';

import "./style.sass";

export const NewBackScreenModal: FunctionComponent<{}> = ({ }) => {

    const [userKeyValue, setName] = useState('');
    const [isinvalidKeyError, setIsInvalidKeyError] = useState(false);
    const { loading: UserKeyLoading, error: UserKeyError, data: UserKeyData } = useQuery(GET_USER_KEYS);
    const [addUserKey] = useMutation(ADD_USER_KEY);

    const { allSearchParams, getNewSearchParamsString, updateParams } = useSearchParams();

    const handleClose = () => {
        updateParams({
            search: getNewSearchParamsString({
                keysToRemove: [
                    'openBackScreenEdit', 'newBackScreen'
                ]
            })
        });
    };

    const handleSave = () => {
        // getUser({ variables: { id: userKeyValue } });
    }

    return (
        <Dialog open={!!allSearchParams.newBackScreen} onClose={handleClose}>
            <Paper>
                <Box
                    minWidth={455}
                >
                    <DialogTitle>
                        New Back Screen
                    </DialogTitle>
                    <DialogContent>

                        <Box display="flex" justifyContent="space-around">

                            <Box flex={1} pt={5}>
                                <form>
                                    <TextField
                                        label="Name"
                                        fullWidth
                                        // size="small"
                                        // variant="outlined"
                                        value={userKeyValue}
                                        onChange={(event: any) => {
                                            setName(event.target.value);
                                        }}
                                        name="name"
                                    />
                                </form>
                            </Box>
                        </Box>

                        <Box p={2}></Box>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleSave} color="secondary">
                            Create Back Screener
                        </Button>
                    </DialogActions>
                </Box>
            </Paper>
        </Dialog>
    );
}
