import { gql } from "@apollo/client";

export const GET_COLOR_SCHEME = gql`
  {
    colorScheme @client {
      mode
    }
  }
`;

export const EDIT_COLOR_SCHEME = gql`
  mutation editColorScheme($mode: String!) {
    editColorScheme(mode: $mode) @client {
      mode
    }
  }
`;