import env from '@env';
import React, { useState, useEffect, useCallback, FunctionComponent } from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect
} from 'react-router-dom';
import { ApolloClient, ApolloProvider, InMemoryCache, gql } from '@apollo/client';
import { persistCache, LocalStorageWrapper, CachePersistor } from 'apollo3-cache-persist';
import loadable from '@loadable/component';
import { Container } from '@mui/material';
import { ThemeProvider, responsiveFontSizes, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { purple, cyan, blue } from '@mui/material/colors';
import { useQuery, useMutation, NormalizedCacheObject } from "@apollo/client";

import { todoResolvers } from '@state/byModel/Todos/todo.resolvers';
import { userKeysResolvers } from '@state/byModel/User/UserKey.localResolvers';
import { screensResolvers } from '@state/byModel/SelectedBackScreens/SelectedBackScreens.localResolvers';
import { selectedRatioCollectionsResolvers } from '@state/byModel/SelectedRatioCollections/SelectedRatioCollections.localResolvers';
import { selectedComparisonsResolvers } from '@state/byModel/SelectedComparisons/SelectedComparisons.localResolvers';
import { colorSchemeResolvers } from '@state/byModel/ColorTheme/colorTheme.resolvers';
import { GET_COLOR_SCHEME, EDIT_COLOR_SCHEME } from "@state/byModel/ColorTheme/colorTheme.queries";

import Header from '@components/shared/Header';
import Footer from '@components/shared/Footer';

function App() {

    const [client, setClient] = useState<any>();
    const [persistor, setPersistor] = useState<
        CachePersistor<NormalizedCacheObject>
    >();

    useEffect(() => {
        async function init() {
            const cache = new InMemoryCache();

            let newPersistor = new CachePersistor({
                cache,
                storage: new LocalStorageWrapper(window.localStorage),
                debug: true,
                trigger: 'write',
                // maxSize: false
            });

            await newPersistor.restore();
            setPersistor(newPersistor);

            setClient(
                new ApolloClient({
                    uri: env.graphql,
                    cache,
                    resolvers: {
                        Mutation: {
                            ...todoResolvers.Mutation,
                            ...userKeysResolvers.Mutation,
                            ...screensResolvers.Mutation,
                            ...selectedRatioCollectionsResolvers.Mutation,
                            ...selectedComparisonsResolvers.Mutation,
                            ...colorSchemeResolvers.Mutation
                        }
                    },
                })
            );



            // try {
            //     const getTodos = await client.readQuery({ query: GET_TODOS });
            //     const getKeys = await client.readQuery({ query: GET_USER_KEYS });
            //     const getScreeners = await client.readQuery({ query: LOCAL_GET_SCREENS });
            //     const getSelectedRatioCollections = await client.readQuery({ query: GET_SELECTED_RATIO_COLLECTIONS });

            //     console.log({
            //         getTodos,
            //         getKeys,
            //         getScreeners,
            //         getSelectedRatioCollections
            //     })

            //     if (!getKeys && !getTodos && !getScreeners && !getSelectedRatioCollections) {
            //         alert("hum");
            //         // client.writeQuery({
            //         //     query: gql`
            //         //       query {
            //         //         todos
            //         //         userKeys
            //         //         screens,
            //         //         selectedRatioCollections
            //         //       }
            //         //     `,
            //         //     data: { todos: [], userKeys: [], screens: [], selectedRatioCollections: [] }
            //         // });
            //     }
            // } catch (error) {
            //     alert({ error });
            //     client.writeQuery({
            //         query: gql``,
            //         data: {
            //             todos: [],
            //             userKeys: [],
            //             screens: [],
            //             selectedRatioCollections: []
            //         }
            //     });
            // }


        }

        init().catch(console.error);
    }, []);

    const clearCache = useCallback(() => {
        if (!persistor) {
            return;
        }
        persistor.purge();
        window.location.reload();
    }, [persistor]);

    if (!client) {
        return <h2>Initializing app...</h2>;
    }

    return (
        <ApolloProvider client={client}>
            <AppContent clearCache={clearCache} />
        </ApolloProvider>
    );
}
export default App;

const AppContent: FunctionComponent<{ clearCache: any }> = ({ clearCache }) => {

    const { loading, error, data: colorSchemeData } = useQuery(GET_COLOR_SCHEME);

    const systemColorScheme = window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light';

    const darkTheme = createTheme(({
        palette: {
            mode: colorSchemeData?.colorScheme?.mode || systemColorScheme,
            primary: blue,
            secondary: blue,
            // primary: purple,
            // secondary: purple,
            // background: {
            //     default: '#212121',
            //     paper: '#333',
            //     // grey1: theme.palette.grey['800']
            // }
        },
    }), { factor: 15 });

    return (
        <ThemeProvider theme={darkTheme}>
            <CssBaseline />
            <Router>
                <Header clearCache={clearCache} />

                <Container>
                    <Switch>
                        <Route
                            path="/todo"
                            component={loadable(() => import('./components/routes/TodoPage'))}
                        />
                        <Route
                            path="/stock/:ticker"
                            component={loadable(() => import('./components/routes/StockPage'))}
                        />
                        <Route
                            path="/ranking/:collectionId/:row"
                            component={loadable(() => import('./components/routes/Ranking'))}
                        />
                        <Route
                            path="/ranking/:row"
                            component={loadable(() => import('./components/routes/Ranking'))}
                        />
                        <Route
                            path="/backscreen/comparing/:comparison/by/:metric/on/:collectionName/:collectionId"
                            component={loadable(() => import('./components/routes/BackScreen'))}
                        />
                        <Route
                            path="/backscreen/:backscreenName/:backscreenId"
                            component={loadable(() => import('./components/routes/BackScreen'))}
                        />
                        <Route
                            path="/backscreen"
                            component={loadable(() => import('./components/routes/BackScreen'))}
                        />
                        <Route
                            path="/home"
                            component={loadable(() => import('./components/routes/Home'))}
                        />

                        <Redirect from="/" to="/home" />
                    </Switch>
                    <Footer />
                </Container>
            </Router>
        </ThemeProvider>
    );
}
