import { gql } from '@apollo/client';
// import RandomID from "random-id";
// import { GET_LOCAL_SCREENS } from "./FilterOptions.localQueries";

export const selectedComparisonsResolvers = {
  Mutation: {
    updateSelectedComparisons: (_: any, variables: any, { cache }: any) => {
      // const { screens } = cache.readQuery({ query: GET_LOCAL_SCREENS });

      // console.log({ selectedComparisons: variables.selectedComparisons?.filter(c => typeof c !== 'undefined') });

      cache.writeQuery({
        query: gql`
          query {
            selectedComparisons {
              value
              title
              type
              filters
            }
          }
        `,
        data: {
          selectedComparisons: [
            ...variables.selectedComparisons
              ?.filter(c => typeof c !== 'undefined')
              ?.map(c => (
                { ...c, filters: c?.filter || null }
              ))
          ]
        }
      });

      return variables.selectedComparisons;
    }
  }
};