import { gql } from "@apollo/client";

export const GET_LAST_YEAR_COUNTS = gql`
    query($query: AggregationInputQuery ) { 
        getLastYearCounts(
            query: $query
        )
    }
`;

export const GET_LAST_YEAR_COUNTS_FOR_BACKSCREENERS = gql`
    query { 
        getLastYearCountsForBackScreeners
    }
`;

export const GET_AGGREGATE_FOR_FINANCIAL_ROW = gql`
    query($query: AggregationInputQuery, $companiesForRow: String ) {
        getAggregateForFinancialRows(
            query: $query,
            companiesForRow: $companiesForRow
        )
    }
`;

export const GET_AGGREGATES_FOR_FINANCIAL_ROWS = (aggregatesList: string[], stock?: any) => {
    const uglyness = (aggregatesList: string[]) => `${JSON.stringify(aggregatesList.reduce(
        (p, v) => ({
            ...p,
            [v]:
                (v => `getAggregateForFinancialRows(query: { ${v[0] === 'Stock_Related' ? v[1].replaceAll("_", " ") : v[0]
                    }: "${v[0] === 'Stock_Related' ? stock[v[1]] : v[1]?.replaceAll("_", " ")
                    }" })`)
                    (v.split('__'))
        }),
        {
            all: `getAggregateForFinancialRows(query: {})`
        }))
        .replaceAll('\\"', '+')
        .replaceAll('"', '')
        .replaceAll('+', '"')
        }`;

    // console.log({ uglyness: uglyness(aggregatesList, stock) });

    return gql`${uglyness(aggregatesList)}`;
}

export const GET_AGGREGATE_FOR_CALC_ROW = gql`
    query($query: AggregationInputQuery, $companiesForRow: String, $collectionId: String ) {
        getAggregateForCalcRows(
            query: $query,
            companiesForRow: $companiesForRow,
            collectionId: $collectionId
        )
    }
`;

export const GET_SCREENED_COMPANIES = gql`
    query($query: AggregationInputQuery, $companiesForRow: String, $filters: [JSON], $row: JSON) {
        getScreenedCompanies(
            query: $query,
            filters: $filters,
            row: $row,
            companiesForRow: $companiesForRow
        )
    }
`;

export const GET_AGGREGATES_FOR_CALC_ROWS = ({
    aggregatesList,
    collectionId,
    companiesForRow
    // calcs,
}: {
    aggregatesList: Array<{ value: string, backScreenId?: string }>,
    collectionId?: string,
    companiesForRow?: string
    // calcs?: any[]
}) => {

    console.log({
        aggregatesList
    });

    const uglyness = (aggregatesList: Array<{ value: string, backScreenId?: string }>) => `${JSON.stringify(aggregatesList.reduce(
        (p, v) => ({
            ...p,
            [v.value?.replaceAll('&', '').replaceAll(new RegExp('[\u1000-\uFFFF]+', 'g'), '')?.trim()]:
                (v_ => `getAggregateForCalcRows(collectionId:"${collectionId}",${companiesForRow ? `companiesForRow: "${companiesForRow}",` : ''}${v.backScreenId ? `backScreenId: "${v.backScreenId}",` : ''}query: {${v_[0] !== 'screener' ? `${v_[0]}: "${v_[1]?.replaceAll("_", " ")?.replaceAll(new RegExp('[\u1000-\uFFFF]+', 'g'), '')?.trim()}"` : ``}})`)
                    (v.value.split('__'))
        }),
        {
            all: `getAggregateForCalcRows(query: {})` // Hacky AF: but this despite doing nothing is needed as the 'skip' functionality from apollo doesn't seem to work without it
        }
    ))
        .replaceAll('\\"', '+')
        .replaceAll('"', '')
        .replaceAll('+', '"')
        }`;

    console.log(uglyness(aggregatesList));

    return gql`${uglyness(aggregatesList)}`;
}

export const GET_AGGREGATE_FOR_CALC_ROWS = gql`
    query($sector: String, $industry: String, $country: String, $exchange: String, $calcs: [JSON] ) { 
        getAggregateForCALCRows(
            sector: $sector,
            industry: $industry,
            country: $country, 
            exchange: $exchange,
            calcs: $calcs
        )
    }
`;
