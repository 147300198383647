import React, { useState, FunctionComponent, useEffect } from "react";
import {
    Box,
    Chip,
    TextField,
    Autocomplete
} from '@mui/material';
import { useQuery, useMutation, useLazyQuery } from "@apollo/client";
import { GET_SELECTED_RATIO_COLLECTIONS, UPDATE_SELECTED_RATIO_COLLECTIONS } from "@state/byModel/SelectedRatioCollections/SelectedRatioCollections.localQueries";

import useSearchParams from '@state/byModel/Global/useSearchParams.effect';

import "./style.sass";

export const RatioCollectionPicker: FunctionComponent<{ ratioCollections: any }> = ({ ratioCollections }) => {

    const { loading: localStorageSelectedRationCollectionsLoading, error: localStorageSelectedRationCollectionsError, data: localStorageSelectedRationCollectionsData } = useQuery(GET_SELECTED_RATIO_COLLECTIONS);
    const [updateRatioCollections] = useMutation(UPDATE_SELECTED_RATIO_COLLECTIONS);

    const options: { value: string, title: string, type: string, isRecommended: boolean, recommendedOrder: number }[] = ratioCollections ?
        ratioCollections?.map((c: any) => ({
            value: c.id,
            title: c.name,
            type: c.isOwnedByPlatform ? 'system' : c.isOwnedByUser ? 'yours' : 'other user\'s',
            isRecommended: c.isRecommended,
            recommendedOrder: c.recommendedOrder
        })) : [];

    const pickedChosenCollections = localStorageSelectedRationCollectionsData?.selectedRatioCollections?.length ? (
        ids => ids?.map(
            ({ value }: any) => options.find((c: any) => c.value === value)
        )
            .filter((o: any) => typeof o !== 'undefined')
    )(localStorageSelectedRationCollectionsData?.selectedRatioCollections) : [];

    const handleCollectionToggle = (e: any, { v }: any) => {
        // console.log({ v });
        updateRatioCollections({
            variables: {
                selectedRatioCollections: v
            }
        });
    }

    return (
        <>
            <Box>
                <Autocomplete
                    size="small"
                    multiple
                    autoComplete
                    autoHighlight
                    // fullWidth={true}
                    // autoSelect
                    // limitTags={2}
                    disableListWrap
                    options={options}
                    getOptionLabel={option => option ? option?.title : ''}
                    value={pickedChosenCollections}
                    // inputValue={v}
                    // onInputChange={(_, newInputValue) => {
                    //     console.log({
                    //         newInputValue
                    //     });
                    // }}
                    // defaultValue={v}
                    groupBy={option => `${option?.type}`}
                    // forcePopupIcon={false}
                    onChange={(event, newInputValue) => {
                        handleCollectionToggle(event, { v: newInputValue });
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            variant="outlined"
                            label={`Selected Metrics`}
                            placeholder=""
                        />
                    )}
                    renderTags={(value: any[], getTagProps) =>
                        value.map((option: any, index: number) => {
                            return (
                                <Box m={0.5} key={`${option.title}`}>
                                    <Chip
                                        label={`${option.title}`}
                                        {...getTagProps({ index })}
                                        className={`${option.type}`}
                                        // color={'default'}
                                        onDelete={undefined}
                                        onClick={(getTagProps({ index }) as any).onDelete}
                                    />
                                </Box>
                            )
                        })
                    }
                />
            </Box>
        </>
    );
}
