import { gql } from '@apollo/client';
// import RandomID from "random-id";
import { GET_COLOR_SCHEME } from "./colorTheme.queries";

export const colorSchemeResolvers = {
  Mutation: {
    editColorScheme: (_: any, colorScheme: any, { cache }: any) => {
      // const { colorScheme } = cache.readQuery({ query: editColorScheme });
      cache.writeQuery({
        query: gql`
        query {
          colorScheme {
            mode
          }
        }
        `,
        data: {
          colorScheme
        }
      });
      // console.log("TODOS: ", cache.readQuery({ query: GET_TODOS }));
      return colorScheme;
    }
  }
};