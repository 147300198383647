import { gql } from "@apollo/client";

export const GET_SELECTED_RATIO_COLLECTIONS = gql`
  {
    selectedRatioCollections @client
  }
`;

export const UPDATE_SELECTED_RATIO_COLLECTIONS = gql`
  mutation updateSelectedRatioCollections($selectedRatioCollections: JSON!) {
    updateSelectedRatioCollections(selectedRatioCollections: $selectedRatioCollections) @client
  }
`;