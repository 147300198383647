import React, { FunctionComponent, useEffect, useRef, useState, useMemo, memo } from 'react';
import * as d3 from "d3";

import * as math from 'mathjs';
import numeral from 'numeral';
import { SvgIcon } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { getCalculatedDataForChart } from './Histrogram.calculations';
import { WhatsBetterType } from '@components/routes/StockPage/CalcRowModal/CalcRowModal.component';
import { findClosestIndex, dataSortedByWhatsBetter, colorByPositionInData } from '@state/byModel/Charts/charts.utils';

export const Histogram: FunctionComponent<{
    width: number,
    height: number,
    numberOfBuckets?: number,
    data: any,
    preCalculatedDataForChart?: any,
    valueOfSelectedCompany?: number,
    visibleCompanyIndexes?: { top: number, bottom: number },
    callbackOnValues?: () => {},
    minValueFromParent?: number,
    maxValueFromParent?: number,
    maxHeightFromParent?: number,
    thresholdsFromParent?: Array<number>,
    showDuringBeautification?: boolean,
    isYCentered?: boolean,
    stringToCauseRerenderWhenChanged?: string,
    showTicks?: boolean,
    bigTicks?: boolean,
    whatsBetter?: WhatsBetterType
}> = memo(({
    width = 150,
    height = 100,
    numberOfBuckets = 20,
    data = [],
    preCalculatedDataForChart,
    valueOfSelectedCompany,
    visibleCompanyIndexes,
    callbackOnValues,
    minValueFromParent,
    maxValueFromParent,
    maxHeightFromParent,
    thresholdsFromParent,
    showDuringBeautification,
    isYCentered = false,
    stringToCauseRerenderWhenChanged,
    showTicks = true,
    bigTicks = false,
    whatsBetter
}) => {

    // console.log({ whatsBetter });

    const theme = useTheme();
    const ref = useRef();
    const refTooltip = useRef();

    const [isTooltipVisible, setIsTooltipVisible] = useState(false);
    const [outlierAttemptCount, setOutlierAttemptCount] = useState(0);

    const [tooltipContent, setTooltipContent] = useState({
        from: 0,
        to: 0,
        numberOfCompanies: 0
    });

    const marginTop = 0;
    const marginRight = 0;
    const marginBottom = showTicks ? 20 : 0;
    const marginLeft = 0;
    // const numberOfBuckets = 20;

    const handleMouseOver = (e, d) => {
        const [mx, my] = d3.pointer(e);

        setIsTooltipVisible(true);

        // for some reason the sorting has some randomness
        const sortedBucket = d.sort((a, b) => b - a);

        setTooltipContent({
            numberOfCompanies: d.length,
            to: sortedBucket[0],
            from: sortedBucket[d.length - 1]
        });

        d3.select(e.currentTarget)
            .style("opacity", 0.8);

    };

    const handleMouseOut = (e, d) => {
        setIsTooltipVisible(false);

        d3.select(e.currentTarget)
            .style("opacity", 1);
    }

    const numberOnlyData = useMemo(() => [...data]?.map(d => d.v)?.sort(d3.descending), [data?.length]);


    // const bins = (thresholdsFromParent?.slice(0, -1))?.map((threshold, i) => {
    //     const bin = dataWithoutOutliers.filter(d => d > threshold && d < thresholdsFromParent[i + 1]);
    //     bin.x0 = threshold;
    //     bin.x1 = thresholdsFromParent[i + 1];

    //     return bin;
    // });


    const preCalculatedDataForChart_withWeirdArrayKeysBack = useMemo(() => {
        if (!!preCalculatedDataForChart) {
            return {
                ...preCalculatedDataForChart,
                bins: preCalculatedDataForChart.bins.map((bin, i) => {
                    bin.x0 = preCalculatedDataForChart.thresholds[i];
                    bin.x1 = preCalculatedDataForChart.thresholds[i + 1];
                    return bin;
                }),
                oulierBins: preCalculatedDataForChart.outlierBins.map((bin, i) => {
                    if(i === 0) {
                        bin.x0 = preCalculatedDataForChart.thresholds[0] - preCalculatedDataForChart.binWidth;
                        bin.x1 = preCalculatedDataForChart.thresholds[0];
                    }
                    if(i === 1) {
                        bin.x0 = preCalculatedDataForChart.thresholds[preCalculatedDataForChart.thresholds.length - 1];
                        bin.x1 = preCalculatedDataForChart.thresholds[preCalculatedDataForChart.thresholds.length - 1] + preCalculatedDataForChart.binWidth;;
                    }
                    return bin;
                })
            }
        } else { return false };
    }, [preCalculatedDataForChart]);


    // console.log({ preCalculatedDataForChart });

    const calculatedDataForChart = useMemo(() => {
        // typeof preCalculatedDataForChart_withWeirdArrayKeysBack !== 'undefined' && preCalculatedDataForChart_withWeirdArrayKeysBack ||
        return typeof preCalculatedDataForChart_withWeirdArrayKeysBack !== 'undefined' && preCalculatedDataForChart_withWeirdArrayKeysBack || typeof numberOnlyData !== 'undefined' && getCalculatedDataForChart({
                numberOnlyData,
                numberOfBuckets,
                visibleCompanyIndexes,
                outlierAttemptCount,
                showDuringBeautification,
                minValueFromParent,
                maxValueFromParent,
                thresholdsFromParent,
                stringToCauseRerenderWhenChanged
            })
    }, [
        preCalculatedDataForChart_withWeirdArrayKeysBack,
        numberOnlyData?.length,
        visibleCompanyIndexes,
        outlierAttemptCount,
        stringToCauseRerenderWhenChanged
    ]);

    // console.log({ calculatedDataForChart, preCalculatedDataForChart, preCalculatedDataForChart_withWeirdArrayKeysBack });

    useEffect(() => {
        if (!calculatedDataForChart.goodEnough) {
            setTimeout(() => {
                setOutlierAttemptCount(outlierAttemptCount + 1)
            }, showDuringBeautification ? 1000 : 0);
        }
    }, [calculatedDataForChart.goodEnough, calculatedDataForChart.outlierAttemptCount])

    const dataSortedByWhatsBetter_ = useMemo(() => dataSortedByWhatsBetter({ data: numberOnlyData, whatsBetter }) || [], [numberOnlyData, whatsBetter]);

    // const positionAsPercentage = Number(((findClosestIndex(valueOfSelectedCompany, dataSortedByWhatsBetter_) / dataSortedByWhatsBetter_.length) * 100).toFixed(1));

    useEffect(() => {
        if (!!calculatedDataForChart && (calculatedDataForChart.goodEnough || showDuringBeautification)) {
            const bucketColor = theme.palette.primary.light;
            const selectedBucketColor = theme.palette.primary.dark;
            const outlierBucketColor = theme.palette.grey[600];
            const textColor = theme.palette.text.secondary;

            const minValue = calculatedDataForChart.minValue;
            const maxValue = calculatedDataForChart.maxValue;

            const x = d3.scaleLinear()
                .domain([
                    minValue - calculatedDataForChart.binWidth,
                    maxValue + calculatedDataForChart.binWidth
                    // (minValueFromParent < minValue && data?.length > 10 ? minValueFromParent : minValue) - calculatedDataForChart.binWidth,
                    // (maxValueFromParent > maxValue && data?.length > 10 ? maxValueFromParent : maxValue) + calculatedDataForChart.binWidth
                ])
                .range([marginLeft, width - marginRight]);

            const maxLength = d3.max([
                ...calculatedDataForChart.bins,
                ...calculatedDataForChart.outlierBins
            ], (d) => d.length);

            // Declare the y (vertical position) scale.
            const y = d3.scaleLinear()
                .domain([
                    0,
                    maxHeightFromParent > maxLength ? maxHeightFromParent : maxLength
                ])
                .range([height - marginBottom, marginTop]);

            // callbackOnValues && callbackOnValues({
            //     minValue,
            //     maxValue,
            //     maxLength
            // });

            const svg = d3
                .select(ref.current)
                .attr("width", width)
                .attr("height", height)
                .attr("viewBox", [0, 0, width, height])
                .attr("style", "max-width: 100%; height: auto; overflow: visible;");

            svg.selectAll("*").remove();

            const mainBins = svg.append("g")
                .selectAll()
                .data(calculatedDataForChart.bins)
                .join("g")
                .attr("cursor", "pointer")
                .on("mouseover", handleMouseOver)
                .on("mouseout", handleMouseOut);


            // Add a background rect for each bin
            mainBins
                .append("rect")
                .attr("fill", (d) => {
                    return 'transparent';
                })
                .attr("x", (d) => x(d.x1) - x(d.x0) > 0 ? x(d.x0) + 1 : x(0) - (width / numberOfBuckets) / 2)
                .attr("width", (d) => x(d.x1) - x(d.x0) > 0 ? x(d.x1) - x(d.x0) - 2 : width / numberOfBuckets)
                .attr("y", (d) => 0)
                .attr("height", height)
                .style("cursor", "pointer")
                .attr("rx", 3)
                .attr("ry", 3);

            // Add a rect for each bin
            mainBins
                .append("rect")
                .attr("fill", (d) => {
                    if (valueOfSelectedCompany) {
                        if (d[d.length - 1] <= valueOfSelectedCompany && d[0] >= valueOfSelectedCompany) {
                            return selectedBucketColor
                        }
                    }
                    if (typeof visibleCompanyIndexes?.top !== 'undefined') {
                        if (d[d.length - 1] <= calculatedDataForChart.visibleCompaniesValueRange.top && d[0] >= calculatedDataForChart.visibleCompaniesValueRange.bottom) {
                            return selectedBucketColor
                        }
                        if (d[d.length - 1] <= calculatedDataForChart.visibleCompaniesValueRange.bottom && d[0] >= calculatedDataForChart.visibleCompaniesValueRange.top) {
                            return selectedBucketColor
                        }
                    }

                    const midPoint = d.x0 + (d.x1 - d.x0) / 2;
                    const colorByPositionInData_ = colorByPositionInData({ valueOfSelectedCompany: midPoint, data: dataSortedByWhatsBetter_ });

                    return colorByPositionInData_;
                })
                .attr("x", (d) => x(d.x1) - x(d.x0) > 0 ? x(d.x0) + 1 : x(0) - (width / numberOfBuckets) / 2)
                .attr("width", (d) => x(d.x1) - x(d.x0) > 0 ? x(d.x1) - x(d.x0) - 2 : width / numberOfBuckets)
                .attr("y", (d) => y(d.length) + (isYCentered ? (- height / 2 + ((y(0) - y(d.length)) / 2)) : 0))
                .attr("height", (d) => y(0) - y(d.length))
                .style("cursor", "pointer")
                .attr("rx", 3)
                .attr("ry", 3);


            // const mainBins = svg.append("g")
            // .selectAll()
            // .data(calculatedDataForChart.bins)
            // .join("g")
            // .attr("cursor", "pointer")
            // .on("mouseover", handleMouseOver)
            // .on("mouseout", handleMouseOut);


            const outlierBins = svg.append("g")
                .selectAll()
                .data(calculatedDataForChart.outlierBins)
                .join("g")
                .attr("cursor", "pointer")
                .on("mouseover", handleMouseOver)
                .on("mouseout", handleMouseOut);

            // Add a background rect for each outlier bin
            outlierBins
                .append("rect")
                .attr("fill", (d) => {
                    return 'transparent';
                })
                .attr("x", (d) => x(d.x1) - x(d.x0) > 0 ? x(d.x0) + 1 : x(0) - (width / numberOfBuckets) / 2)
                .attr("width", (d) => x(d.x1) - x(d.x0) > 0 ? x(d.x1) - x(d.x0) - 2 : width / numberOfBuckets)
                .attr("y", (d) => 0)
                .attr("height", height)
                .style("cursor", "pointer")
                .attr("rx", 3)
                .attr("ry", 3);

            // Add a rect for each outlier bin
            outlierBins
                .append("rect")
                .attr("fill", (d) => {
                    if (valueOfSelectedCompany) {
                        if (d[d.length - 1] <= valueOfSelectedCompany && d[0] >= valueOfSelectedCompany) {
                            return selectedBucketColor
                        }
                    }
                    if (typeof visibleCompanyIndexes?.top !== 'undefined') {
                        if (d[d.length - 1] <= calculatedDataForChart.visibleCompaniesValueRange.top && d[0] >= calculatedDataForChart.visibleCompaniesValueRange.top) {
                            return selectedBucketColor
                        }
                        if (d[d.length - 1] <= calculatedDataForChart.visibleCompaniesValueRange.bottom && d[0] >= calculatedDataForChart.visibleCompaniesValueRange.bottom) {
                            return selectedBucketColor
                        }
                    }

                    return outlierBucketColor
                })
                .attr("x", (d) => x(d.x0) + 1)
                .attr("width", (d) => x(d.x1) - x(d.x0) - 2)
                .attr("y", (d) => y(d.length) + (isYCentered ? (- height / 2 + ((y(0) - y(d.length)) / 2)) : 0))
                .attr("height", (d) => y(0) - y(d.length))
                .attr("rx", 3)
                .attr("ry", 3);


            if (valueOfSelectedCompany) {
                const selectedBin = [
                    ...calculatedDataForChart.bins,
                    ...calculatedDataForChart.outlierBins
                ].find(d => d[d.length - 1] <= valueOfSelectedCompany && d[0] >= valueOfSelectedCompany);
                if (selectedBin) {
                    svg.append("polygon")
                        .attr(
                            "points",
                            `
                                ${x(selectedBin.x0) + (x(selectedBin.x1) - x(selectedBin.x0)) / 2},
                                ${y(selectedBin.length) - 5} ${x(selectedBin.x0) + (x(selectedBin.x1) - x(selectedBin.x0)) / 2 - 5},
                                ${y(selectedBin.length) - 15} ${x(selectedBin.x0) + (x(selectedBin.x1) - x(selectedBin.x0)) / 2 + 5},
                                ${y(selectedBin.length) - 15}
                            `
                        )
                        .attr("fill", selectedBucketColor)
                    // .attr("y",  y(0));
                }
            }


            // svg.append("g")
            //     .selectAll()
            //     .data(bins)
            //     .join("g")
            //     // .attr("transform", "translate(-"+width+","+height+")")
            //     // .attr("transform", "translate(150, 140) rotate(270)") // breaks
            //     .append("text")
            //     .attr("x", (d) => x(d.x0) + 1)
            //     .attr("y", (d) => y(d.length) - 20)
            //     .attr("fill", "blue")
            //     .attr("font-size", "10px")
            //     .attr("text-anchor", "start")
            //     .text((d) => parseFloat(d[0]).toFixed(2) + " - " + parseFloat(d[d.length - 1]).toFixed(2))



            // left value

            // if (data?.length) {
            //     svg.append("g")
            //         .call((g) => g.append("text")
            //             .attr("x", x(calculatedDataForChart.bins[0].x0))
            //             .attr("y", y(calculatedDataForChart.bins[0].length) < 15 ? 15 : y(calculatedDataForChart.bins[0].length) - 5)
            //             .attr("fill", textColor)
            //             .attr("text-anchor", "start")
            //             .text(
            //                 numeral(minValue).format('(0.00a)')
            //             ));
            //     if (minValue !== maxValue) {
            //         svg.append("g")
            //             .call((g) => g.append("text")
            //                 .attr("x", x(calculatedDataForChart.bins[calculatedDataForChart.bins.length - 1].x1))
            //                 .attr("y", y(calculatedDataForChart.bins[calculatedDataForChart.bins.length - 1].length) < 15 ? 15 : y(calculatedDataForChart.bins[calculatedDataForChart.bins.length - 1].length) - 5)
            //                 .attr("fill", textColor)
            //                 .attr("text-anchor", "end")
            //                 .text(
            //                     numeral(maxValue).format('(0.00a)')
            //                 ));
            //     }
            // }



            // Add the x-axis and label.
            if (showTicks) {
                svg.append("g")
                    .attr("transform", `translate(0,${height - marginBottom})`)
                    .style("color", textColor)
                    .style("font-size", bigTicks ? "12px" : "10px")
                    .style("font-weight", bigTicks ? "bold" : "normal")
                    .call(d3.axisBottom(x).ticks(Math.floor(width / 50)).tickSize(3).tickFormat(function (d) {
                        return `${numeral(d).format('(0.00a)')}`;
                    }))
                    .call(g => g.select(".domain")
                        .style("color", "transparent")
                    );
            }
            // .call((g) => g.append("text")
            //     .style("color", textColor)
            //     .attr("fill", textColor)
            //     .attr("x", width)
            //     .attr("y", marginBottom - 2)
            //     .attr("text-anchor", "end"));
            // .text("Unemployment rate (%) →"));

            // // Add the y-axis and label, and remove the domain line.
            // svg.append("g")
            //     .attr("transform", `translate(${marginLeft},0)`)
            //     .call(d3.axisLeft(y).ticks(height / 40))
            //     .call((g) => g.select(".domain").remove())
            //     .call((g) => g.append("text")
            //         .attr("x", -marginLeft)
            //         .attr("y", 10)
            //         .attr("fill", "currentColor")
            //         .attr("text-anchor", "start")
            //         .text("↑ Frequency (no. of counties)"));
        }
    }, [
        calculatedDataForChart.goodEnough,
        showDuringBeautification && calculatedDataForChart.outlierAttemptCount,
        visibleCompanyIndexes,
        minValueFromParent,
        maxValueFromParent,
        maxHeightFromParent,
        stringToCauseRerenderWhenChanged
    ]);

    const clickedRoot = () => {
        console.log({ data, whatsBetter });
    };

    return <div style={{ position: 'relative', width: `${width}px`, height: `${height}px` }} onClick={clickedRoot}>
        {!!calculatedDataForChart && !calculatedDataForChart.goodEnough ?
            <div style={{ position: 'absolute', top: '6px', left: 0, lineHeight: '12px', opacity: 0.5 }}>
                🎨 iteration {calculatedDataForChart.outlierAttemptCount}
            </div> : null}
        <svg width={width} height={height} ref={ref} />
        <div ref={refTooltip} style={{
            position: 'absolute',
            bottom: 'calc(50% - 13px)',
            left: 'calc(50% - 80px)',
            opacity: isTooltipVisible ? 1 : 0,
            background: 'rgba(0,0,0,0.6)',
            color: 'rgba(255,255,255,0.9)',
            width: '160px',
            pointerEvents: 'none',
            fontFamily: "Roboto",
            fontSize: "12px",
            borderRadius: "3px",
            lineHeight: "18px"
        }}>
            <div style={{ padding: '2px', textAlign: 'center' }}>
                <p style={{ margin: '0', marginBottom: '-5px' }}>{tooltipContent.numberOfCompanies} compan{!tooltipContent.numberOfCompanies || tooltipContent.numberOfCompanies > 1 ? 'ies' : 'y'}</p>
                {!!tooltipContent.numberOfCompanies ? (
                    <>
                        {tooltipContent.numberOfCompanies > 1 ? (
                            <p style={{ margin: '0' }}>within {numeral(tooltipContent.from).format('(0.00a)')} and {numeral(tooltipContent.to).format('(0.00a)')}</p>
                        ) : (
                            <p style={{ margin: '0' }}>with {numeral(tooltipContent.from).format('(0.00a)')}</p>
                        )}
                    </>
                ) : (<p style={{ margin: '4px' }}>{' '}</p>)}
            </div>
        </div>
    </div>
})

