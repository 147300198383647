import { gql } from "@apollo/client";

export const GET_SELECTED_COMPARISONS = gql`
  {
    selectedComparisons @client
  }
`;

export const UPDATE_SELECTED_COMPARISONS = gql`
  mutation updateSelectedComparisons($selectedComparisons: JSON!) {
    updateSelectedComparisons(selectedComparisons: $selectedComparisons) @client
  }
`;