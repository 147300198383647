import React, { useState, useEffect, useMemo } from "react";
import { Link, useLocation, useRouteMatch } from 'react-router-dom';
import { useQuery, useMutation, useLazyQuery } from "@apollo/client";
import SearchIcon from '@mui/icons-material/Search';
import { Box, Avatar, Typography, ClickAwayListener, Divider } from '@mui/material';
import numeral from 'numeral';
import CircularProgress from '@mui/material/CircularProgress';

import { SEARCH_QUERY } from '@state/byModel/Stocks/stocks.queries';
import useSearchParams from '@state/byModel/Global/useSearchParams.effect';
import { GET_BACK_SCREENS } from "@state/byModel/BackScreens/BackScreens.queries";
import { BackScreenProvider, useBackScreenProvider } from "@components/routes/BackScreen/BackScreen.context";
import { GET_USER_KEYS } from '@state/byModel/User/UserKey.localQueries';
import { GET_LOCAL_SCREENS, UPDATE_LOCAL_SCREENS } from "@state/byModel/SelectedBackScreens/SelectedBackScreens.localQueries";
import { GET_STOCK } from '@state/byModel/Stocks/stocks.queries';

import { useStyles } from './styles';

import "./style.sass";

export default function StockBackScreenerSearcher() {
    const location = useLocation();
    
    const isInMacroComparison = location.pathname.includes('/macrocomparison');

    const isInBackScreener = location.pathname.includes('/backScreen') || location.pathname.includes('/backscreen');

    const { loading: UserKeysLoading, error: UserKeysError, data: UserKeysData } = useQuery(GET_USER_KEYS);
    const { loading: localStorageScreensLoading, error: localStorageScreensError, data: localStorageScreensData, refetch: updateScreens_ } = useQuery(GET_LOCAL_SCREENS);

    const { loading: backScreenOptionsLoading, error: backScreenOptionsError, data: backScreenOptions } = useQuery(GET_BACK_SCREENS, {
        variables: {
            userId: UserKeysData?.userKeys?.length > 0 ? UserKeysData?.userKeys[0].id : ""
        },
    });

    const m = useRouteMatch("/stock/:ticker");
    const ticker = m?.params?.ticker;

    const { loading: stock_loading, error: stock_error, data: stock_data } = useQuery(GET_STOCK, {
        variables: { ticker },
        skip: !ticker
    });

    const stock = useMemo(() => stock_data && stock_data.getStockByCode, [stock_data]);

    const options: { value: string, title: string, type: string }[] = useMemo(() =>
        backScreenOptions?.getBackScreens ?
            backScreenOptions?.getBackScreens?.map((c: any) => ({
                ...c,
                // value: c.id,
                // title: c.name,
                filters: c.filters || [],
                rows: c.rows || [],
                type: c.isOwnedByPlatform ? 'system' : c.isOwnedByUser ? 'yours' : 'other user\'s'
            })) : [],
        [backScreenOptions]);

    const selectedBackScreen = useMemo(
        () => localStorageScreensData?.screens?.length ? localStorageScreensData?.screens?.[0] : [],
        [localStorageScreensData?.screens]
    );

    return (
        <>

            {isInMacroComparison ? (
                <Box display="flex" sx={{ display: { xs: 'none', sm: 'none', md: 'flex', lg: 'flex' } }}>
                    <Box display="flex">
                        Macro Comparison
                    </Box>
                </Box>
            ) : null}
            {selectedBackScreen?.name && isInBackScreener ? (
                <Box display="flex" sx={{ display: { xs: 'none', sm: 'none', md: 'flex', lg: 'flex' } }}>
                    <Box display="flex" sx={{ opacity: 0.3 }} pr={1}>
                        Backscreen:
                    </Box>
                    <Box display="flex">
                        {selectedBackScreen.name}
                    </Box>
                </Box>
            ) : null}

            {stock ? (
                <Box display="flex" sx={{ display: { xs: 'none', sm: 'none', md: 'none', lg: 'flex' } }} flexDirection="row" justifyContent="space-between" alignItems="center" flexWrap="wrap" mb="-15px">
                    <Box display="flex" flexDirection="row" pb="15px">
                        <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                            mr={1}
                        >
                            <Avatar className="stock-avatar" variant="rounded" src={`//${stock.logo}`} />
                        </Box>
                        <Box display="flex" flexDirection="column" mt={0}>
                            <Box display="flex" alignItems="center" mr={2} mb={-1}>
                                <Typography variant="h6">
                                    {stock.name}
                                </Typography>
                                <Box ml={1}>
                                    <Typography variant="body2">
                                        {stock.code}
                                    </Typography>
                                </Box>
                            </Box>
                            <Box display="flex" alignItems="flex-start">
                                <Typography
                                    display="block"
                                    noWrap={true}
                                    variant="subtitle2"
                                    lineHeight="20px"
                                >
                                    <span title="sector">{stock.sector}</span>
                                    {" "}・{" "}
                                    <span title="industry">{stock.industry}</span>
                                    {" "}・{" "}
                                    <span title="exchange">{stock.exchange}</span>
                                    {" "}・{" "}
                                    <span title="country">{stock.country}</span>
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            ) : null}

        </>
    );
}
