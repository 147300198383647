import React, { FunctionComponent, useState, useEffect } from "react";
import { useParams, useRouteMatch, useLocation } from "react-router-dom";
import { useQuery, useMutation, useLazyQuery } from "@apollo/client";
import { LinkedIn } from '@mui/icons-material';
import {
    Paper,
    Box,
    Typography,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Button,
    TextField,
    Fab,
    Chip,
    Link,
    Autocomplete
} from '@mui/material';

import { GET_USER_BY_ID } from '@state/byModel/User/User.queries';
import { GET_USER_KEYS, ADD_USER_KEY } from "@state/byModel/User/UserKey.localQueries";
import useSearchParams from '@state/byModel/Global/useSearchParams.effect';

import "./style.sass";

export const LoginModal: FunctionComponent<{}> = ({ }) => {

    const [userKeyValue, setUserKeyValue] = useState('');
    const [isinvalidKeyError, setIsInvalidKeyError] = useState(false);
    const { loading: UserKeyLoading, error: UserKeyError, data: UserKeyData } = useQuery(GET_USER_KEYS);
    const [addUserKey] = useMutation(ADD_USER_KEY);

    const { allSearchParams, getNewSearchParamsString, updateParams } = useSearchParams();

    const [getUser, { loading, error, data }] = useLazyQuery(GET_USER_BY_ID);
    // const [saveRatioCollection] = useMutation(SAVE_RATIO_COLLECTION);

    // console.log({ UserKeyData });

    useEffect(() => {
        // console.log({
        //     user: data?.getUserById
        // });

        if (data?.getUserById) {
            addUserKey({
                variables: {
                    id: userKeyValue
                }
            });
            handleClose();
        } else if (data) {
            setIsInvalidKeyError(true);
        }
    }, [data?.getUserById]);

    const handleClose = () => {
        updateParams({ search: getNewSearchParamsString({ keysToRemove: ['isRegistering'] }) })
    };

    const handleLogin = () => {
        getUser({ variables: { id: userKeyValue } });
    }

    return (
        <Dialog open={!!allSearchParams.isRegistering} onClose={handleClose}>
            <Paper>
                <Box
                    minWidth={455}
                >
                    <DialogTitle>
                        Enter User Key
                    </DialogTitle>
                    <DialogContent>

                        <Box mt={-2} color="text.secondary">
                            
                            <p>
                                You only need to be logged in if you'd like to create
                                and edit your own Calculated Metrics or Backscreens.
                            </p>
                            <p>
                                Settings, visited stocks and backscreens get saved on your browser.
                            </p>
                            <p>
                                To get your own User Key please:
                                {' '}
                                <Link href="https://www.linkedin.com/in/esperancajs/" target="_blank">
                                    <Button variant="contained">
                                        <Box display="flex" alignItems="center">
                                            <Box mr={1}>
                                                Send me a message
                                            </Box>
                                            <LinkedIn fontSize="inherit" />
                                        </Box>
                                    </Button>
                                </Link>
                            </p>
                        </Box>

                        <Box display="flex" justifyContent="space-around">

                            <Box flex={1} pt={5}>
                                <form>
                                    <TextField
                                        label="User Key"
                                        fullWidth
                                        // size="small"
                                        // variant="outlined"
                                        value={userKeyValue}
                                        onChange={(event: any) => {
                                            setUserKeyValue(event.target.value);
                                        }}
                                        name="one-time-code"
                                    />
                                </form>
                            </Box>

                        </Box>

                        <Box p={2}></Box>

                        {isinvalidKeyError && (
                            <p>We couldn't find anyone with that key.</p>
                        )}

                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleLogin} color="secondary">
                            Login
                        </Button>
                    </DialogActions>
                </Box>
            </Paper>
        </Dialog>
    );
}
