import { alpha } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
    tooltip: {
        position: 'relative',
        display: 'inline-block',

        '&:after': {
            content: '""',
            position: 'absolute',
            top: '99%',
            left: '50%',
            transform: 'translateX(-50%)',
            borderWidth: '15px',
            borderStyle: 'solid',
            borderColor: `${theme.palette.divider} transparent transparent transparent`,
            zIndex: -1
        }
    },
    elementHover: {
        position: 'absolute',
        top: '100%',
        left: '50%',
        transform: 'translateX(-50%)',
        background: 'transparent',
        width: '80px',
        height: '40px'   
    }
}));