// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../node_modules/css-loader/dist/cjs.js!./assets/fonts/dm-font.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cursor-pointer{cursor:pointer}.cursor-pointer:hover{opacity:.7}`, "",{"version":3,"sources":["webpack://./src/index.scss"],"names":[],"mappings":"AAEA,gBACI,cAAA,CAEA,sBACI,UAAA","sourcesContent":["@import \"assets/fonts/dm-font.css\";\n\n.cursor-pointer {\n    cursor: pointer;\n    \n    &:hover {\n        opacity: 0.7;\n    }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
