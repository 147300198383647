import React from 'react';
import ReactDOM from 'react-dom/client';

import App from './App';
import './index.scss';

(async () => {
    const domNode = document.getElementById('app');
    const root = ReactDOM.createRoot(domNode);
    root.render(
        <App />
    );
})();
