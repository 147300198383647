import * as d3 from "d3";
import { WhatsBetterType } from "@components/routes/StockPage/CalcRowModal/CalcRowModal.component";

export const findClosestIndex = (value: number, array: Array<number>) => {
    let closestIndex = -1;
    let closestDifference = Infinity;

    array.forEach((element, index) => {
        const difference = Math.abs(element - value);
        if (difference < closestDifference) {
            closestDifference = difference;
            closestIndex = index;
        }
    });

    return closestIndex;
}

export const dataSortedByWhatsBetter = (
    { data, whatsBetter }: 
    { data: Array<number>, whatsBetter?: WhatsBetterType }
) => {
    if (!data) return [];
    if (!whatsBetter || whatsBetter === 'high') return data || [];
    if (whatsBetter === 'low') return data.reverse();
    else if (whatsBetter === 'low_above_zero') {
        const sortedData = [
            ...data.filter(v => v >= 0).sort((a, b) => a - b), // positive values (from lowest to highest)
            ...data.filter(v => v < 0).sort((a, b) => b - a) // negative values  (from highest to lowest)
        ]
        return sortedData;
    }
};

export const colorByPositionInData = (
    { valueOfSelectedCompany, data }: 
    { valueOfSelectedCompany: number, data: Array<number> }
) => {
    const positionAsPercentage = Number(((findClosestIndex(valueOfSelectedCompany, data) / data.length) * 100).toFixed(1));

    const isTopOrBottom = positionAsPercentage < 50 ? 'top' : 'bottom';

    const positionAsPercentageInverted = Number((100 - positionAsPercentage).toFixed(1));

    const topColor = d3.scaleLinear([50, 0], ['white', 'green']);
    const bottomColor = d3.scaleLinear([0, 50], d3.schemeReds[3]);

    if (isTopOrBottom === 'top') {
        return topColor(positionAsPercentage)
    } else {
        return bottomColor(positionAsPercentage)
    }
}