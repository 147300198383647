import { gql } from "@apollo/client";

export const GET_LOCAL_SCREENS = gql`
  {
    screens @client
  }
`;

export const UPDATE_LOCAL_SCREENS = gql`
  mutation updateScreens($screens: JSON!) {
    updateScreens(screens: $screens) @client
  }
`;