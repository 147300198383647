import { useQuery } from "@apollo/client";
import { useMemo } from "react";

import useSearchParams from '@state/byModel/Global/useSearchParams.effect';
import { GET_LAST_YEAR_COUNTS, GET_LAST_YEAR_COUNTS_FOR_BACKSCREENERS } from '@state/byModel/Aggregate/aggregate.queries';
import { GET_SELECTED_COMPARISONS, UPDATE_SELECTED_COMPARISONS } from "@state/byModel/SelectedComparisons/SelectedComparisons.localQueries";


export default function getComparisonOptions() {

    const { allSearchParams } = useSearchParams();
    const { loading: lastYearCounts_loading, error: lastYearCounts_error, data: lastYearCounts } = useQuery(GET_LAST_YEAR_COUNTS, {
        variables: {
            query: {}
        }
    });

    const { loading: lastYearCountsForBackScreeners_loading, error: lastYearCountsForBackScreeners_error, data: lastYearCountsForBackScreeners } = useQuery(GET_LAST_YEAR_COUNTS_FOR_BACKSCREENERS);

    // console.log({ lastYearCountsForBackScreeners });

    const comparisonOptions: { value: string, title: string, type: string, backScreenId?: string, filters?: any }[] = useMemo(() => [
        ...(lastYearCountsForBackScreeners?.getLastYearCountsForBackScreeners) ? lastYearCountsForBackScreeners?.getLastYearCountsForBackScreeners?.counts?.map(c => {
            return {
                value: `screener__${c._id?.replaceAll(" ", "_")}`,
                title: `${c._id} (${c.count})`,
                type: 'Screener',
                backScreenId: c.id,
                filters: c.filters
            }
        }) : [],
        // lazy: refactor
        ...(lastYearCounts?.getLastYearCounts?.counts.country) ?
            lastYearCounts?.getLastYearCounts?.counts?.country?.map((c: any) => ({
                value: `country__${c._id?.replaceAll(" ", "_")}`,
                title: `${c._id} (${c.count})`,
                type: 'Country'
            })) :
            [],
        ...(lastYearCounts?.getLastYearCounts?.counts.exchange) ?
            lastYearCounts?.getLastYearCounts?.counts?.exchange?.map((c: any) => ({
                value: `exchange__${c._id?.replaceAll(" ", "_")}`,
                title: `${c._id} (${c.count})`,
                type: 'Exchange'
            })) :
            [],
        ...(lastYearCounts?.getLastYearCounts?.counts.sector) ?
            lastYearCounts?.getLastYearCounts?.counts?.sector?.map((c: any) => ({
                value: `sector__${c._id?.replaceAll(" ", "_")}`,
                title: `${c._id} (${c.count})`,
                type: 'Sector'
            })) :
            [],
        ...(lastYearCounts?.getLastYearCounts?.counts.industry) ?
            lastYearCounts?.getLastYearCounts?.counts?.industry?.map((c: any) => ({
                value: `industry__${c._id?.replaceAll(" ", "_")}`,
                title: `${c._id} (${c.count})`,
                type: 'Industry'
            })) :
            []
    ], [lastYearCounts?.getLastYearCounts, lastYearCountsForBackScreeners?.getLastYearCountsForBackScreeners]);


    const {
        loading: localStorageSelectedComparisonsLoading,
        error: localStorageSelectedComparisonsError,
        data: localStorageSelectedComparisonsData
    } = useQuery(GET_SELECTED_COMPARISONS);

    // console.log({ localStorageSelectedComparisonsData });

    const pickedComparisons = useMemo(() => localStorageSelectedComparisonsData?.selectedComparisons?.map(c => c.value), [localStorageSelectedComparisonsData?.selectedComparisons]);

    const getComparisonOption = (value: string) => {
        const option = comparisonOptions?.find((c: any) => c.value === value);

        return option;
    };

    // const pickedComparisons = allSearchParams.comparisons?.split('-')
    //     .filter((c: any) => typeof c !== 'undefined');

    const pickedComparisonsOptions = useMemo(() => pickedComparisons
        ?.map(v => comparisonOptions?.find((c: any) => c.value === v))
        .filter((c: any) => typeof c !== 'undefined'), [pickedComparisons, comparisonOptions]);


    // console.log({
    //     pickedComparisonsOptions, comparisonOptions
    // })

    return {
        comparisonOptions,
        getComparisonOption,
        pickedComparisons,
        pickedComparisonsOptions,
        comparisonOptionsLoading: lastYearCounts_loading
    };
}