// Imports handled where these functions are used

// import * as d3 from "d3";
// import math from "mathjs";

const convertToNumberOnlyData = (data, {
    d3, math
}) => data?.sort(d3.descending);

const getCalculatedDataForChart = (
    {
        numberOnlyData,
        numberOfBuckets = 20,
        visibleCompanyIndexes,
        outlierAttemptCount = 0,
        showDuringBeautification,
        minValueFromParent,
        maxValueFromParent,
        thresholdsFromParent,
        stringToCauseRerenderWhenChanged,
    }, {
        d3, math
    }
) => {

    if (!numberOnlyData.length) { return false; }

    const calculateBinnedData = (numberOnlyData, numberOfBuckets, outlierAttemptCount) => {

        // We currently use 2 strategies, the MAD method and the quantile method, 
        // but should not calculate both

        // Approximated Quantile Method
        const quantileBottomChoice = ((numberOfBuckets * (0 + 0.01 * Math.pow(outlierAttemptCount - 1, 3))) / 100);
        const quantileTopChoice = 1 - ((numberOfBuckets * (0 + 0.01 * Math.pow(outlierAttemptCount - 1, 3))) / 100);
        const quantileBottom = d3.quantile(numberOnlyData, (quantileBottomChoice));
        const quantileTop = d3.quantile(numberOnlyData, (quantileTopChoice));
        const topOutlierDataByEdgeQuantiles = typeof quantileTop !== 'undefined' && numberOnlyData.filter(d => d >= quantileTop);
        const bottomOutlierDataByEdgeQuantiles = typeof quantileBottom !== 'undefined' && numberOnlyData.filter(d => d <= quantileBottom);
        const dataWithoutOutliersByEdgeQuantiles = typeof quantileBottom !== 'undefined' && typeof quantileTop !== 'undefined' && quantileBottom !== quantileTop ? numberOnlyData.filter(d => d > quantileBottom && d < quantileTop) : numberOnlyData;

        // MAD method
        const MAD = math.mad(numberOnlyData);
        const median = math.median(numberOnlyData);
        const Ztop = 4;
        const Zbottom = 4;
        const dataWithRobustZScore = numberOnlyData?.map(d => [d, 0.6745 * (d - median) / MAD]);
        const topOutlierDataRobustZ = dataWithRobustZScore.filter(([d, z]) => z > Ztop)?.map(([d, z]) => d);
        const bottomOutlierDataRobustZ = dataWithRobustZScore.filter(([d, z]) => z < -Zbottom)?.map(([d, z]) => d);
        const dataWithoutOutliersRobustZ = dataWithRobustZScore.filter(([d, z]) => z > -Zbottom && z < Ztop)?.map(([d, z]) => d);

        const robustZWorks = MAD !== 0;

        const topOutlierData = outlierAttemptCount === 0 && robustZWorks ? topOutlierDataRobustZ : topOutlierDataByEdgeQuantiles;
        const bottomOutlierData = outlierAttemptCount === 0 && robustZWorks ? bottomOutlierDataRobustZ : bottomOutlierDataByEdgeQuantiles;
        const dataWithoutOutliers = outlierAttemptCount === 0 && robustZWorks ? dataWithoutOutliersRobustZ : dataWithoutOutliersByEdgeQuantiles;

        // Bin the data.
        const bins = d3.bin()
            .thresholds(numberOfBuckets)
            .value((d) => d)
            (dataWithoutOutliers);

        const binWidth = bins[0]?.x1 - bins[0].x0;

        // Yep, looks a bit odd, because D3 adds indices to the x0 and x1 bin items, and we are using the same weird syntax
        const topOutlierBin = topOutlierData && [...topOutlierData];
        const bottomOutlierBin = bottomOutlierData && [...bottomOutlierData];
        if (topOutlierData) {
            topOutlierBin['x0'] = bins[bins.length - 1].x1;
            topOutlierBin['x1'] = bins[bins.length - 1].x1 + binWidth;
        }
        if (bottomOutlierBin) {
            bottomOutlierBin['x0'] = bins[0].x0 - binWidth;
            bottomOutlierBin['x1'] = bins[0].x0;
        }
        const outlierBins = [bottomOutlierBin, topOutlierBin];

        const max = d3.max(bins.map(b => b.length)) || 0;
        const maxOutliers = d3.max(outlierBins.map(b => b?.length));
        const goodEnough = robustZWorks || (max / 2) < (maxOutliers) || outlierAttemptCount > 3; // 🏆 🏆 🏆

        // console.log('calculateBinnedData', {
        //     robustZWorks,
        //     bins,
        //     dataWithoutOutliers,
        //     outlierAttemptCount,
        //     topOutlierData,
        //     bottomOutlierData
        // });

        return {
            bins,
            topOutlierData,
            bottomOutlierData,
            goodEnough,
            binWidth,
            outlierBins
        };
    };

    const fittedToParentThresholdBinnedData = (numberOnlyData, thresholdsFromParent) => {
        const dataWithoutOutliers = numberOnlyData.filter(d => d > thresholdsFromParent[0] && d < thresholdsFromParent[thresholdsFromParent.length - 1]);
        const topOutlierData = numberOnlyData.filter(d => d > thresholdsFromParent[thresholdsFromParent.length - 1]);
        const bottomOutlierData = numberOnlyData.filter(d => d < thresholdsFromParent[0]);

        const bins = (thresholdsFromParent?.slice(0, -1))?.map((threshold, i) => {
            const bin = dataWithoutOutliers.filter(d => d > threshold && d < thresholdsFromParent[i + 1]);
            bin.x0 = threshold;
            bin.x1 = thresholdsFromParent[i + 1];

            return bin;
        });

        // console.log('fittedToParentThresholdBinnedData', { 
        //     bins,
        //     dataWithoutOutliers
        // });

        const binWidth = bins[0]?.x1 - bins[0].x0;

        // Yep, looks a bit odd, because D3 adds indices to the x0 and x1 bin items, and we are using the same weird syntax
        const topOutlierBin = topOutlierData && [...topOutlierData];
        const bottomOutlierBin = bottomOutlierData && [...bottomOutlierData];
        if (topOutlierData) {
            topOutlierBin['x0'] = bins[bins.length - 1].x1;
            topOutlierBin['x1'] = bins[bins.length - 1].x1 + binWidth;
        }
        if (bottomOutlierBin) {
            bottomOutlierBin['x0'] = bins[0].x0 - binWidth;
            bottomOutlierBin['x1'] = bins[0].x0;
        }
        const outlierBins = [bottomOutlierBin, topOutlierBin];

        return {
            bins,
            topOutlierData,
            bottomOutlierData,
            goodEnough: true,
            binWidth,
            outlierBins
        };
    };

    const { bins, topOutlierData, bottomOutlierData, goodEnough, binWidth, outlierBins } = thresholdsFromParent ?
        fittedToParentThresholdBinnedData(numberOnlyData, thresholdsFromParent) :
        calculateBinnedData(numberOnlyData, numberOfBuckets, outlierAttemptCount);


    // TODO: separate this from this function
    // as it's only needed for the screener, I'll leave it for now
    const visibleCompaniesValueRange = {
        top: typeof visibleCompanyIndexes?.top !== 'undefined' ? numberOnlyData[visibleCompanyIndexes.top] : null,
        bottom: typeof visibleCompanyIndexes?.bottom !== 'undefined' ? numberOnlyData[visibleCompanyIndexes.bottom] : null
    };

    const minValue = bins[0].x0;
    const maxValue = bins[bins.length - 1].x1;

    const thresholds = [...bins.map(b => b.x0), bins[bins.length - 1].x1];

    // console.log({
    //     // quantileBottom,
    //     // quantileTop,
    //     // outlierAttemptCount,
    //     // topOutlierData,
    //     // bottomOutlierData,
    //     thresholdsFromParent,
    //     numberOfBuckets,
    //     bins,
    //     thresholds
    // });

    // console.log({
    //     max,
    //     maxOutliers,
    //     goodEnough
    // });

    // setIsGoodEnough(true);
    // if ((max / 2) < (maxOutliers) || outlierAttemptCount > 0) {
    //     setIsGoodEnough(true);
    // } else if (!isGoodEnoug) {
    //     setOutlierAttemptCount(outlierAttemptCount + 1)
    // }


    // console.log({
    //     outlierAttemptCount,
    //     data,
    //     numberOnlyData,
    //     bins,
    //     mean,
    //     median,
    //     deviation,
    //     variance,
    //     quantileBottom,
    //     quantileTop,
    //     MAD,
    //     topOutlierData,
    //     bottomOutlierData,
    //     dataWithoutOutliers,
    //     binWidth,
    //     outlierBins,
    //     topOutlierBin,
    //     visibleCompaniesValueRange
    // });

    return {
        bins,
        binWidth,
        outlierBins,
        visibleCompaniesValueRange,
        goodEnough,
        outlierAttemptCount,
        minValue,
        maxValue,
        thresholds
    }
};

const getCalculatedDataForChartUntillGoodEnough = (
    { numberOnlyData, numberOfBuckets = 20, visibleCompanyIndexes, outlierAttemptCount = 0, showDuringBeautification, thresholdsFromParent },
    {
        d3, math
    }
) => {
    if (!numberOnlyData?.length) {
        return false;
    } else {
        const attempt = getCalculatedDataForChart(
            { numberOnlyData, numberOfBuckets, visibleCompanyIndexes, outlierAttemptCount, showDuringBeautification, thresholdsFromParent },
            { d3, math }
        );

        if (!attempt.goodEnough) {
            return getCalculatedDataForChartUntillGoodEnough(
                { numberOnlyData, numberOfBuckets, visibleCompanyIndexes, outlierAttemptCount: outlierAttemptCount + 1, showDuringBeautification, thresholdsFromParent },
                { d3, math }
            );
        } else {
            return attempt
        }
    }
};

export {
    convertToNumberOnlyData,
    getCalculatedDataForChart,
    getCalculatedDataForChartUntillGoodEnough
};