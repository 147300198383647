import React, { useState, FunctionComponent, useContext } from "react";
import { useQuery, useMutation } from "@apollo/client";
import {
    Box,
    Chip,
    TextField,
    Autocomplete
} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';

import { GET_SELECTED_COMPARISONS, UPDATE_SELECTED_COMPARISONS } from "@state/byModel/SelectedComparisons/SelectedComparisons.localQueries";
import useSearchParams from '@state/byModel/Global/useSearchParams.effect';
import getComparisonOptions from '@state/byModel/ComparisonOptions/ComparisonOptions.effect';


import './style.sass';

export const ComparisonsPicker: FunctionComponent<{}> = ({ }) => {
    const { allSearchParams, getNewSearchParamsString, updateParams } = useSearchParams();
    const { comparisonOptions, comparisonOptionsLoading } = getComparisonOptions();

    const {
        loading: localStorageSelectedComparisonsLoading, 
        error: localStorageSelectedComparisonsError, 
        data: localStorageSelectedComparisonsData
    } = useQuery(GET_SELECTED_COMPARISONS);
    const [updateRatioCollections] = useMutation(UPDATE_SELECTED_COMPARISONS);

    const pickedComparisons = localStorageSelectedComparisonsData?.selectedComparisons?.length ? (
        ids => ids?.map(
            ({ value }) => comparisonOptions.find((c: any) => c.value === value)
        )
            .filter((o: any) => typeof o !== 'undefined')
    )(localStorageSelectedComparisonsData?.selectedComparisons) : [];

    // console.log({ localStorageSelectedComparisonsData, pickedComparisons });

    // console.log({
    //     pickedComparisons,
    //     // c: allSearchParams.comparisons,
    //     // c1: allSearchParams.comparisons?.split('-'),
    // });

    const handleComparisonToggle = (e: any, { v }: any) => {
        updateRatioCollections({
            variables: {
                selectedComparisons: v
            }
        });

        // updateParams({
        //     search: getNewSearchParamsString({
        //         ...v.length && {
        //             paramsToAdd: {
        //                 comparisons: v.reduce((p: any, c: any) => `${p ? p + '-' : ''}${c.value}`, '')
        //             }
        //         },
        //         ...!v.length && {
        //             keysToRemove: ['comparisons']
        //         }
        //     })
        // })
    }

    // console.log({ pickedComparisons, comparisons: allSearchParams.comparisons });

    return (
        <Box display="flex">
            {comparisonOptionsLoading ? (
                <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    mr={comparisonOptionsLoading ? 3 : 0}
                >
                    {comparisonOptionsLoading && <CircularProgress />}
                </Box>
            ) : null}
            <Box minWidth="170px">
                <Autocomplete
                    size="small"
                    multiple
                    autoComplete
                    autoHighlight
                    // autoSelect
                    // limitTags={1}
                    // disableListWrap
                    options={comparisonOptions}
                    getOptionLabel={option => option ? option?.title : ''}
                    value={pickedComparisons}
                    loading={comparisonOptionsLoading}
                    // inputValue={v}
                    // onInputChange={(_, newInputValue) => {
                    //     console.log({
                    //         newInputValue
                    //     });
                    // }}
                    // defaultValue={v}
                    groupBy={option => `${option?.type}`}
                    // forcePopupIcon={false}
                    onChange={(event, newInputValue) => {
                        handleComparisonToggle(event, { v: newInputValue });
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            variant="outlined"
                            label={`Comparing with`}
                            placeholder=""
                        />
                    )}
                    renderTags={(value: any[], getTagProps) =>
                        value.map((option: any, index: number) => {
                            return (
                                <Box m={0.5}>
                                    <Chip
                                        label={`${option.type}: ${option.title}`}
                                        {...getTagProps({ index })}
                                        className={`${option.type}`}
                                        // color={'default'}
                                        onDelete={undefined}
                                        onClick={(getTagProps({ index }) as any).onDelete}
                                    />
                                </Box>
                            )
                        })
                    }
                />
            </Box>
        </Box>
    );
}

