import { useState, useEffect } from 'react';
import { useMutation, useQuery } from "@apollo/client";

import { GET_RATIO_COLLECTIONS } from '@state/byModel/Calculations/calculations.queries';
import { GET_USER_KEYS } from "@state/byModel/User/UserKey.localQueries";

import { GET_SELECTED_RATIO_COLLECTIONS, UPDATE_SELECTED_RATIO_COLLECTIONS } from "@state/byModel/SelectedRatioCollections/SelectedRatioCollections.localQueries";

export default function useSetDefaultPickedRatioCollections() {

    const [noCollectionPickedYet, setNoCollectionPickedYet] = useState(true);

    const { loading: UserKeysLoading, error: UserKeysError, data: UserKeysData } = useQuery(GET_USER_KEYS);
    const { loading: localStorageSelectedRationCollectionsLoading, error: localStorageSelectedRationCollectionsError, data: localStorageSelectedRationCollectionsData } = useQuery(GET_SELECTED_RATIO_COLLECTIONS);

    const {
        loading: ratioCollections_loading, error: ratioCollections_error, data: ratioCollectionsQ
    } = useQuery(GET_RATIO_COLLECTIONS, {
        variables: {
            userId: UserKeysData?.userKeys?.length > 0 ? UserKeysData?.userKeys[0].id : ""
        },
        // skip: !stock
    });

    const [updateRatioCollections] = useMutation(UPDATE_SELECTED_RATIO_COLLECTIONS);

    const ratioCollections = ratioCollectionsQ?.getRatioCollections;

    const pickedCollections = localStorageSelectedRationCollectionsData?.selectedRatioCollections?.length ? (
        ids => ids?.map(
            ({ value }: any) => ratioCollections?.find((c: any) => c.id === value)
        )
            .filter((o: any) => typeof o !== 'undefined')
    )(localStorageSelectedRationCollectionsData?.selectedRatioCollections) : [];

    const options: { value: string, title: string, type: string, isRecommended: boolean, recommendedOrder: number }[] = ratioCollections ?
        ratioCollections?.map((c: any) => ({
            value: c.id,
            title: c.name,
            type: c.isOwnedByPlatform ? 'system' : c.isOwnedByUser ? 'yours' : 'other user\'s',
            isRecommended: c.isRecommended,
            recommendedOrder: c.recommendedOrder
        })) : [];


    useEffect(() => {
        if (noCollectionPickedYet && pickedCollections?.length === 0 && !!options.length) {
            console.log({
                options
            });
            updateRatioCollections({
                variables: {
                    selectedRatioCollections: options
                        ?.filter(o => o.isRecommended === true)
                        ?.sort((a, b) => a.recommendedOrder - b.recommendedOrder)
                }
            });
            setNoCollectionPickedYet(false);
        }
        if (noCollectionPickedYet && !!pickedCollections?.length) {
            setNoCollectionPickedYet(false);
        }
    }, [pickedCollections, options, noCollectionPickedYet]);


    return { pickedCollections };
}