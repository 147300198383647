import React, { createContext, useContext, useState, useEffect, useRef } from "react";

interface ContextType {
    showtooltip: boolean,
    isMouseOverTooltip: boolean,
    tooltipType: 'awards' | 'histogram' | 'test' | null,
    tooltipData: any,
    tooltipPosition: { x: number, y: number },

    openTooltip?: Function,
    closeTooltip: Function,
    setMouseOverTooltip?: Function,
    closeIfMouseNotOverTooltip?: Function,
}

export const TooltipDataContext = createContext<ContextType>({
    showtooltip: false,
    isMouseOverTooltip: false,
    tooltipType: null,
    tooltipData: {},
    tooltipPosition: { x: 0, y: 0 },
    openTooltip: () => { },
    closeTooltip: () => { }
});


export const TooltipDataContextProvider = ({ children }: { children: any }) => {
    const [showtooltip, setShowtooltip] = useState(false);
    const [isMouseOverTooltip, setIsMouseOverTooltip] = useState(false);
    const [tooltipType, setTooltipType] = useState(null);
    const [tooltipData, setTooltipData] = useState(undefined);
    const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 });

    const isMouseOverTooltipRef = useRef(isMouseOverTooltip);

    useEffect(() => {
        isMouseOverTooltipRef.current = isMouseOverTooltip;
    }, [isMouseOverTooltip]);

    interface AnchorRef {
        current: HTMLElement | null;
    }

    const openTooltip = (e: MouseEvent, data: any, anchorRef: AnchorRef) => {
        const position = anchorRef.current?.getBoundingClientRect() ?
            {
                x: anchorRef.current.getBoundingClientRect().left - 184,
                y: anchorRef.current.getBoundingClientRect().top - 215
            } : {
                x: e.clientX - 200,
                y: e.clientY - 220
            }

        setTooltipPosition(position);
        setTooltipType(data.tooltipType);
        setTooltipData(data.tooltipData);
        setShowtooltip(true);
    };

    const setMouseOverTooltip = () => {
        setIsMouseOverTooltip(true);
    };

    const closeTooltip = () => {
        setShowtooltip(false);
        setIsMouseOverTooltip(false);
    };

    const closeIfMouseNotOverTooltip = () => {
        setTimeout(() => {
            if (!isMouseOverTooltipRef.current) {
                setShowtooltip(false);
            }
        }, 100);
    };

    return (
        <TooltipDataContext.Provider value={{
            showtooltip,
            isMouseOverTooltip,
            tooltipType,
            tooltipData,
            tooltipPosition,
            openTooltip,
            setMouseOverTooltip,
            closeTooltip,
            closeIfMouseNotOverTooltip
        }}>
            {children}
        </TooltipDataContext.Provider>
    );
};

export const useTooltipDataContextProvider = () => {
    return useContext(TooltipDataContext);
};

// export { TooltipDataContext, TooltipDataContextProvider };


