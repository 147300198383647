import * as d3 from "d3";
const math = require("mathjs");

import {
    convertToNumberOnlyData as convertToNumberOnlyData_,
    getCalculatedDataForChart as getCalculatedDataForChart_,
    getCalculatedDataForChartUntillGoodEnough as getCalculatedDataForChartUntillGoodEnough_,
} from "@fe-be-shared/histogram.calculations";

export const getCalculatedDataForChart = (
    {
        numberOnlyData,
        numberOfBuckets = 20,
        visibleCompanyIndexes,
        outlierAttemptCount = 0,
        showDuringBeautification,
        minValueFromParent,
        maxValueFromParent,
        thresholdsFromParent,
        stringToCauseRerenderWhenChanged,
    }:
        {
            numberOnlyData: Array<number>,
            numberOfBuckets?: number,
            visibleCompanyIndexes?: any,
            outlierAttemptCount?: number,
            showDuringBeautification?: boolean,
            minValueFromParent?: number,
            maxValueFromParent?: number,
            thresholdsFromParent?: Array<number>,
            stringToCauseRerenderWhenChanged?: string
        }
) => {
    return getCalculatedDataForChart_({
        numberOnlyData,
        numberOfBuckets,
        visibleCompanyIndexes,
        outlierAttemptCount,
        showDuringBeautification,
        minValueFromParent,
        maxValueFromParent,
        thresholdsFromParent,
        stringToCauseRerenderWhenChanged,
    }, { d3, math });
};

export const getCalculatedDataForChartUntillGoodEnough = (
    { numberOnlyData, numberOfBuckets = 20, visibleCompanyIndexes, outlierAttemptCount = 0, showDuringBeautification }:
        { numberOnlyData: any, numberOfBuckets?: number, visibleCompanyIndexes?: any, outlierAttemptCount?: number, showDuringBeautification?: boolean }
) => {
    return getCalculatedDataForChartUntillGoodEnough_({
        numberOnlyData,
        numberOfBuckets,
        visibleCompanyIndexes,
        outlierAttemptCount,
        showDuringBeautification
    });
};
